<template>
  <div>
    <div class="header-text">
      <h1>{{ $t("listAccomodation.title") }}</h1>
    </div>
    <section class="container content py-5">
      <div class="">
        <h1>{{ $t("listAccomodation.title") }}</h1>
        <p>
          {{ $t("listAccomodation.description") }}
        </p>
      </div>
      <a href="#" class="btn btn-main px-4 py-2">{{
        $t("listAccomodation.register")
      }}</a>
    </section>
    <StatisticsSection />
  </div>
</template>

<script>
import StatisticsSection from "../components/StatisticsSection.vue";
export default {
  name: "ListAccomodation",
  components: { StatisticsSection },
};
</script>

<style scoped>
.header-text {
  padding: 30px 0 60px 0;
  color: #fff;
  background: #ff762b;
  color: white;
  text-align: center;
}

.header-text h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 45px;
}

.content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #ff762b;
}
.content h3 {
  font-size: 28px;
  color: #444444;
}
.content p {
  color: #969696;
  line-height: 35px;
}

.contact-card {
  line-height: 30px;
}
@media only screen and (max-width: 500px) {
  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .content h1 {
    font-size: 28px;
  }

  .content h3 {
    font-size: 25px;
  }

  .header-text h1 {
    font-size: 30px;
  }
}
</style>
